import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1
      style={{
        marginTop: '45vh',
        marginBottom: '45vh',
        textAlign: 'center',
        fontFamily: 'Impact',
      }}
    >
      Will Strasser 🤗🤗<br/>
      testing deploy summary.
    </h1>
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
